// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import React from "react"
import { ResponsivePie } from "@nivo/pie"
import "./storyDistribution.scss"

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export const StoryDistribution = [
  {
    id: "JS",
    label: "JS",
    value: 20,
    color: "hsl(223, 70%, 50%)",
  },
  {
    id: "Frontend",
    label: "Frontend",
    value: 25,
    color: "hsl(191, 70%, 50%)",
  },
  {
    id: "React",
    label: "React",
    value: 35,
    color: "hsl(190, 70%, 50%)",
  },
  {
    id: "Mimari",
    label: "Mimarisi",
    value: 20,
    color: "hsl(258, 70%, 50%)",
  },
]

export const StoryDistributionChart = ({ data /* see data tab */ }) => (
  <div className="story-distribution-container">
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      innerRadius={0.6}
      padAngle={1.5}
      cornerRadius={3}
      colors={{ scheme: "category10" }}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextColor="#333333"
      radialLabelsLinkColor={{ from: "color" }}
      sliceLabelsSkipAngle={10}
      sliceLabelsTextColor="#333333"
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "rgba(255, 255, 255, 0.3)",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "JS",
          },
          id: "dots",
        },
        {
          match: {
            id: "React",
          },
          id: "dots",
        },
        {
          match: {
            id: "_Mimari",
          },
          id: "dots",
        },
        {
          match: {
            id: "Frontend",
          },
          id: "lines",
        },
        {
          match: {
            id: "_Temel",
          },
          id: "lines",
        },
      ]}
      legends={[]}
    />
  </div>
)
